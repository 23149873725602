/// <reference path="./../_reference.ts"/>
module Apex.Core.Kendo {
    export interface IGridButtons {
        delete?: boolean;
        edit?: { enabled: boolean, id?: string };
        add?: { enabled: boolean, id?: string };
        lockButtons?: boolean;
        exportToExcell?: boolean;
        multiDelete?: { enabled: boolean, id?: string };
    }

    export interface IGridSettings {
        virtualScroll?: boolean;
        filterDisabled?: boolean;
        selectable?: string;
        sortable?: Core.Kendo.SortType;
        groupable?: boolean,
        navigatable?: boolean;
        autoBind?: boolean;
        pageable?: boolean;
        editble?: {
            disableEditRow?: boolean;
            popupTemplateID?: string;
            popupWidth?: number;
        };
        gridButtons?: IGridButtons;
        height?: number;
        scrollable?: boolean;
    }

    export enum Format {
        Number, Date, DateTime, DateTime_S, Price4, Percent, Percent0, Price2, None, Spend
    }

    export enum SortType {
        single,
        multiple
    }

    export class GridView {
        public static initColumns(cols: Array<Core.Interfaces.IGridColumn>, columnTemplates: Array<Core.Interfaces.IGridColumnTemplate> = null): Array<kendo.ui.GridColumn> {
            var columns: Array<kendo.ui.GridColumn> = new Array();
            var translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            if (cols !== undefined && cols !== null)
                cols.forEach(item => {
                    var gridColumn: kendo.ui.GridColumn = { field: item.field, width: item.width, hidden: item.hidden };
                    if (typeof item.titleTranslated !== "undefined") {
                        gridColumn.title = "{{'" + item.titleTranslated + "' | translate}}";
                        gridColumn.headerTemplate = '<div uib-tooltip="{{\'' + item.titleTranslated + '\' | translate}}" style="text-overflow: ellipsis;display: inline;" tooltip-append-to-body="true" tooltip-animation="true" translate="' + item.titleTranslated + '"> </div>';
                    } else 
                    if (typeof item.title !== "undefined") {
                        gridColumn.title = item.title;
                        gridColumn.headerTemplate = '<div uib-tooltip="{{\'' + item.title + '\' }}" style="text-overflow: ellipsis;display: inline;" tooltip-append-to-body="true" tooltip-animation="true" >' + item.title + ' </div>';
                    } else {
                        gridColumn.title = ' ';
                    }
                    if (item.filterable === undefined) {
                        gridColumn.filterable = {
                            cell: {
                                showOperators: true,
                                operator: item.filterOperator === null || item.filterOperator === undefined ? 'contains' : item.filterOperator
                            }
                        }
                    } else if (item.filterable === 'numeric') {
                        gridColumn.filterable = {
                            cell: {
                                showOperators: false,
                                operator: "eq"
                            }
                        }
                    }
                    else if (item.filterable === 'boolean') {
                        gridColumn.filterable = {
                            multi: true, cell: {
                                template: (args) => {
                                    var elem: JQuery = args.element;
                                    elem.kendoDropDownList({
                                        dataSource: [
                                            { value: null, text: translateProvider.instant('ALL') },
                                            { value: true, text: translateProvider.instant('isSelected') },
                                            { value: false, text: translateProvider.instant('isNotSelected') }
                                        ],
                                        dataTextField: "text",
                                        dataValueField: "value"
                                    });
                                }, showOperators: false
                            }
                        }
                    }
                    else if (item.filterable === 'custom' && item.filterTemplate != undefined) {
                        gridColumn.filterable = {
                            multi: true,
                            cell: {
                                template: item.filterTemplate,
                                showOperators: false
                            }
                        }
                    }
                    else {
                        gridColumn.filterable = false;
                    }


                    var attributeClass = item.cssClass === null || item.cssClass === undefined ? '' : item.cssClass;

                    if (item.editable) {
                        attributeClass = "apx-grid-cl-editable ";
                    }

                    if (item.footerTemplate != undefined) {
                        gridColumn.footerTemplate = item.footerTemplate;
                    }

                    if (item.groupHeaderTemplate != undefined)
                        gridColumn.groupHeaderTemplate = item.groupHeaderTemplate;


                    if (item.format !== undefined) {

                        var twoWay = item.twoWayBinding ? "" : "::";

                        switch (item.format) {
                            case Format.Number:
                                attributeClass = attributeClass + ' apx-number';
                                if (item.editable && item.editor === undefined) {
                                    gridColumn.editor = (container, options) => {
                                        var field = options.field;
                                        var hiddenInput = $('<input id="apx-col-hidden" style="display:none"  name="' + options.field + '"/>').appendTo(container);
                                        var apxNumber = $('<apx-number options="{numberType:0, allowMinus: false}" onblur="Apex.Core.Utils.Helper.changeInput(this)" style= "width:100% " name="' + options.field + '"></apx-number>')
                                            .appendTo(container);
                                    }
                                }
                                break;
                            case Format.Date:
                                // gridColumn.template = '<span>{{' + twoWay + 'dataItem.' + gridColumn.field + ' | dateFormat | date:"dd/MM/yyyy"}}</span>';
                                gridColumn.template ='<span>#= (data.'+gridColumn.field+') ? kendo.toString(kendo.parseDate('+gridColumn.field+', "yyyy-MM-dd"), "dd/MM/yyyy") : "" # </span>';
                                if (item.editable && item.editor === undefined) {
                                    gridColumn.editor = (container, options) => {
                                        $('<apx-date class="apx-col-edit-' + options.field + '" ng-model="dataItem.' + options.field + '"></apx-date>')
                                            .appendTo(container);
                                    }
                                }
                                
                                break;
                            case Format.DateTime:
                                // gridColumn.template = '<span>{{' + twoWay + 'dataItem.' + gridColumn.field + ' | dateFormat | date:"dd/MM/yyyy HH:mm:ss"}}</span>';
                                gridColumn.template ='<span>#= (data.'+gridColumn.field+') ? kendo.toString(kendo.parseDate('+gridColumn.field+', "yyyy-MM-ddTHH:mm:ss"), "dd/MM/yyyy HH:mm:ss") : "" # </span>';
                                break;
                            case Format.DateTime_S:
                                gridColumn.template ='<span>#= (data.'+gridColumn.field+') ? kendo.toString(kendo.parseDate('+gridColumn.field+', "yyyy-MM-ddTHH:mm:ss"), "dd/MM/yyyy HH:mm") : "" # </span>';
                                break;
                            case Format.Price4:
                                attributeClass = attributeClass + ' apx-number';
                                gridColumn.template = '<span ng-hide="(dataItem.' + gridColumn.field + ' == 0)">{{' + twoWay + 'dataItem.' + gridColumn.field + ' |  number:4}}</span>';
                                if (item.editable && item.editor === undefined) {
                                    gridColumn.editor = (container, options) => {
                                        var field = options.field;
                                        var hiddenInput = $('<input id="apx-col-hidden" style="display:none"  name="' + options.field + '"/>').appendTo(container);
                                        var apxNumber = $('<apx-number class="apx-col-edit-' + options.field + '" options="{decimals: 4, allowMinus: false, numberType: Components.NumberType.Decimal }" onblur="Apex.Core.Utils.Helper.changeInput(this)" style= "width:100% " name="' + options.field + '"></apx-number>')
                                            .appendTo(container);
                                    }
                                }
                                break;
                            case Format.Price2:
                                attributeClass = attributeClass + ' apx-number';
                                gridColumn.template = '<div ng-hide="(dataItem.' + gridColumn.field + ' ==0)">{{' + twoWay + 'dataItem.' + gridColumn.field + ' |  number:2}}</div>';
                                if (item.editable && item.editor === undefined) {
                                    gridColumn.editor = (container, options) => {
                                        var field = options.field;
                                        var hiddenInput = $('<input id="apx-col-hidden" style="display:none"  name="' + options.field + '"/>').appendTo(container);
                                        var apxNumber = $('<apx-number class="apx-col-edit-' + options.field + '" options="{decimals: 2, allowMinus: false, numberType: Components.NumberType.Decimal }" onblur="Apex.Core.Utils.Helper.changeInput(this)" style= "width:100% " name="' + options.field + '"></apx-number>')
                                            .appendTo(container);
                                    }
                                }
                                break;
                            case Format.Percent0:
                                attributeClass = attributeClass + ' apx-number';
                                gridColumn.template = '<span>{{' + twoWay + 'dataItem.' + gridColumn.field + ' | number:0 }}%</span>';
                                break;
                            case Format.Percent:
                                attributeClass = attributeClass + ' apx-number';
                                gridColumn.template = '<span>{{' + twoWay + '(dataItem.' + gridColumn.field + ' * 100) | number:2 }}%</span>';
                                break;
                            case Format.Spend:
                                attributeClass = attributeClass + 'apx-spend-column';
                        }
                    } else {
                        if (item.editable && item.editor === undefined) {
                            gridColumn.editor = (container, options) => {
                                $('<apx-textbox class="apx-col-edit-' + options.field + '" ng-model="' + options.field + '"  style="width:100%" name="' + options.field + '"></apx-textbox>').appendTo(container);
                            }
                        }
                    }

                    gridColumn.attributes = { "class": attributeClass };


                    if (columnTemplates !== null && typeof columnTemplates !== "undefined") {
                        var result = $.grep(columnTemplates, e => (e.fieldName === item.field));
                        if (result.length !== 0) {
                            if (item.withTooltip) {
                                gridColumn.template = '<div  uib-tooltip="{{dataItem.' + item.field + '}}" tooltip-append-to-body="true" tooltip-animation="true"> ' + result[0].template + '</div>';;
                            }
                            else if (typeof result[0].template !== "undefined") {
                                gridColumn.template = result[0].template;
                            }
                            if (typeof result[0].footerTemplate !== "undefined")
                                gridColumn.footerTemplate = result[0].footerTemplate;

                            if (typeof result[0].editor !== "undefined")
                                gridColumn.editor = result[0].editor;

                            if (typeof result[0].editformat !== "undefined")
                                gridColumn.format = result[0].editformat;

                            if (typeof result[0].groupHeaderTemplate !== "undefined")
                                gridColumn.groupHeaderTemplate = result[0].groupHeaderTemplate;

                        } else if (item.withTooltip) {
                            gridColumn.template = '<div uib-tooltip="{{dataItem.' + item.field + '}}" tooltip-append-to-body="true" tooltip-animation="true">{{dataItem.' + item.field + '}}</div>';
                        }
                    }

                    if (item.template !== undefined) {
                        gridColumn.template = item.template;
                    }
                    columns.push(gridColumn);
                });
            return columns;
        }
        public static initGridOptions(colmns: Array<Core.Interfaces.IGridColumn>, columnsTemplates: Array<Core.Interfaces.IGridColumnTemplate> = null, settings: IGridSettings = null): Core.Interfaces.IGridOptions {
            var translateProvider = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var gridOptions: Core.Interfaces.IGridOptions = {
                dataSource:{
                    data:[],
                    pageSize: 50
                },
                sortable: {
                    mode: "multiple",
                    allowUnsort: true
                },
                selectable: "single",
                reorderable: false,
                allowCopy: true,
                resizable: true,
                navigatable: true,
                filterable: {
                    mode: "row",
                    messages: {
                        info: 'ინფო',
                        and: 'და',
                        cancel: 'უარყოფა',
                        clear: 'გასუფთავება',
                        filter: 'ფილტრი',
                        isFalse: 'არააქტიური',
                        isTrue: 'აქტიური',
                        operator: 'ოპერატორი',
                        or: 'ან',
                        selectValue: 'აირჩიეთ მნიშვნელობა',
                        value: 'მნიშვნელობა'
                    },
                    operators: {
                        string: {
                            eq: "უდრის",
                            neq: "არ უდრის",
                            startswith: "იწყება",
                            contains: "შეიცავს",
                            doesnotcontain: "არ შეიცავს",
                            endswith: "მთავრდება",
                            isnull: "უდრის null-ს",
                            isnotnull: "არ უდრის null-ს"
                        },
                        number: {
                            eq: "უდრის",
                            neq: "არ უდრის",
                            gt: "მეტია",
                            gte: "მეტია ან ტოლი",
                            lte: "ნაკლებია ან ტოლი",
                            lt: "ნაკლებია",
                            isnull: "უდრის null-ს",
                            isnotnull: "არ უდრის null-ს"
                        },
                        date: {
                            eq: "ტოლია",
                            neq: "არ უდრის",
                            gte: "მეტია ან ტოლი",
                            gt: "მეტია",
                            lte: "ნაკლებია ან ტოლი",
                            lt: "ნაკლებია",
                            isnull: "უდრის null-ს",
                            isnotnull: "არ უდრის null-ს"
                        }
                    }
                },
                columns: GridView.initColumns(colmns, columnsTemplates)

            }

            if (settings !== null && typeof settings !== "undefined") {

                var addCaption = translateProvider.instant('Add');
                var exportToExcelCaption = translateProvider.instant('Export');
                var deleteCaption = translateProvider.instant('Delete');
                var groupHeaderText = //translateProvider.instant('groupPanelText');
                    'მოათავსეთ სვეტი დაჯგუფებისათვის';
                var editCaption = translateProvider.instant('Edit');
                var updateCaption = translateProvider.instant('Save');
                var cancelCaption = translateProvider.instant('Logout');
                var deleteConfirmation = translateProvider.instant('deleteConfirmation');
                if (settings.filterDisabled != undefined && settings.filterDisabled == true) {
                    gridOptions.filterable = {
                        mode: 'none'
                    };
                }

                if (settings.sortable != undefined && settings.sortable == Core.Kendo.SortType.single) {
                    gridOptions.sortable = {
                        mode: "single",
                        allowUnsort: false
                    }
                }


                if (settings.scrollable != undefined && settings.scrollable == false) {
                    gridOptions.scrollable = false;
                }

                if (settings.selectable != undefined) {
                    gridOptions.selectable = settings.selectable;
                }

                if (settings.groupable != undefined && settings.groupable == true) {
                    gridOptions.groupable = { enabled: true, messages: { empty: groupHeaderText } };
                }

                if (settings.pageable) {
                    gridOptions.pageable = {
                        pageSizes: [25, 50, 75, 100],
                        buttonCount: 6,
                        messages: {
                            display: "{0}-{1} ელემენტი {2}-დან",
                            empty: "მონაცემები არ მოიძებნა",
                            itemsPerPage: "ელემენტი თითო გვერდზე"
                        }
                    };
                }
                if (settings.autoBind != undefined) {
                    gridOptions.autoBind = settings.autoBind;
                }
                if (settings.navigatable != undefined) {
                    gridOptions.navigatable = settings.navigatable;
                }

                var addColumn: kendo.ui.GridColumn =
                    {
                        command:
                        [
                            {
                                name: 'edit',
                                text: {
                                    update: updateCaption, cancel: cancelCaption
                                }
                            }
                        ],
                        hidden: true,
                        title: "&nbsp;",
                        width: "90px"
                    };

                gridOptions.columns.push(addColumn);

                if (typeof settings.gridButtons !== "undefined") {
                    if (settings.gridButtons.delete) {
                        var deleteColumn: kendo.ui.GridColumn =
                            {
                                command: [
                                    {
                                        name: 'destroy',
                                        text: deleteCaption
                                    }
                                ],
                                title: "&nbsp;",
                                width: "90px"
                            };

                        if (settings.gridButtons.lockButtons)
                            deleteColumn.locked = true;


                        gridOptions.editable = { confirmation: deleteConfirmation };
                        gridOptions.columns.push(deleteColumn);

                    }


                    if (settings.gridButtons.add && settings.gridButtons.add.enabled) {
                        if (settings.gridButtons.add.id == undefined) {
                            gridOptions.toolbar = [{ name: "createPopup", template: '<a class="k-button k-button-icontext k-grid-edit"><span class="fa fa-plus"></span> ' + addCaption + '</a>' }];
                        }
                        else {
                            gridOptions.toolbar = [{ name: "createPopup", template: '<a id=add' + settings.gridButtons.add.id + ' class="k-button k-button-icontext k-grid-edit"><span class="fa fa-plus"></span> ' + addCaption + '</a>' }];
                        }

                        if (!settings.editble)
                            settings.editble = {};
                    }

                    if (settings.gridButtons.edit) {

                        if (settings.gridButtons.edit.id == undefined) {
                            gridOptions.toolbar.push({ name: "editPopup", template: '<a class="k-button k-button-icontext k-grid-edit-row"><span class="k-icon k-edit"></span>' + editCaption + '</a>' });
                        }
                        else {
                            gridOptions.toolbar.push({ name: "editPopup", template: '<a id=edit' + settings.gridButtons.edit.id + ' class="k-button k-button-icontext k-grid-edit-row"><span class="k-icon k-edit"></span>' + editCaption + '</a>' });
                        }

                    }

                    if (gridOptions.toolbar == undefined)
                        gridOptions.toolbar = [];

                    if (settings.gridButtons.exportToExcell) {
                        gridOptions.toolbar.push({ name: "excel", template: '<a class="k-button k-button-icontext k-grid-excel" > <span class="k-icon k-i-excel"></span>' + exportToExcelCaption + '</a>' });
                        gridOptions.excel = { allPages: true, filterable: true };
                    }

                    if (settings.gridButtons.multiDelete != undefined && settings.gridButtons.multiDelete.enabled) {
                        if (settings.gridButtons.multiDelete.id == undefined) {
                            gridOptions.toolbar.push({ name: "multiDelete", template: '<a id=multiDelete class="k-button k-button-icontext k-grid-delete" href="\\#"><span class="fa fa-times"> </span> ' + deleteCaption + '</a>' });
                        }
                        else {
                            gridOptions.toolbar.push({ name: "multiDelete", template: '<a id=multiDelete' + settings.gridButtons.multiDelete.id + ' class="k-button k-button-icontext k-grid-delete" href="\\#"><span class="fa fa-times"> </span> ' + deleteCaption + '</a>' });
                        }
                        // gridOptions.editable = { confirmation: deleteConfirmation };
                    }
                }
               
                if (settings.editble) {                    
                    if (settings.editble.disableEditRow === true)
                        gridOptions.editable = false;
                    else
                        gridOptions.editable = true;
                }

                if (settings.virtualScroll) {
                    gridOptions.scrollable = {
                        virtual: true
                    };
                    gridOptions.pageable = {
                        info:true,
                        numeric: false,
                        previousNext: false
                    };
                }
                if (settings.height) {
                    gridOptions.height = settings.height;
                }
            }

            gridOptions.setCustomFilter = (filterParams) => {
                if (typeof gridOptions.grid !== "undefined") {
                    var filterRow = '';
                    var i = 0;
                    filterParams.forEach(filter => {
                        i++;
                        if (filter.filterValue != null && filter.filterValue != undefined && filter.filterValue.length > 0) {
                            filterRow += filter.filterName + filter.filterOperator + '=' + filter.filterValue;
                            if (i < filterParams.length) {
                                filterRow += '&';
                            }
                        }
                    });
                    let read = <kendo.data.DataSourceTransportRead>gridOptions.grid.dataSource.options.transport.read;
                    let url = read.url.split('?')[0];
                    read.url = filterRow !== '' ? url + '?' + filterRow : url;
                }
            }
            return gridOptions;
        }
        public static dataToDataSource(data: Array<any>): kendo.data.ObservableArray {
            if (data === undefined)
                data = [];
            return new kendo.data.ObservableArray(data);
        }

        public static initDataSource = (func: Function, param: string): kendo.data.DataSource => {
            var dataSource = new kendo.data.DataSource({
                pageSize: 50,
                transport: {
                    read: (options) => {
                        var fn: any = func();
                        fn.then(result => {
                            options.success(result[param]);
                        });
                    }
                }
            });

            return dataSource;
        }

        private static getCompanyCode() {
            var rootScope = Apex.Core.Utils.Helper.getServiceFromJs("$rootScope");
            if (!!rootScope.userData && !!rootScope.userData.currentCompany) {
                return rootScope.userData.currentCompany.Code;
            }
            return "";
        }

        public static initAutoQueryDataSource(readUrl: string, schemaModel: any = null, dh: string=null, filterParams:string=null): kendo.data.DataSource {
            
            if (schemaModel === null) {
                schemaModel = {
                    fields: {
                    }
                }
            }
            var dataSource = new kendo.data.DataSource({
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                pageSize: 50,
                transport: {
                    read: {
                        url: Global.Settings.serviceUrl + readUrl,
                        dataType: "jsonp"
                    },
                    parameterMap(o, operation) {
                        var output = null;
                        switch (operation) {
                            case "read":
                                var pages = 'skip=' + o.skip;
                                if (o.take) {
                                    pages += '&take=' + o.take;
                                } else {
                                    return '';
                                }
                                var sort = '';
                                var filtes = '';

                                if (!!o.sort && o.sort.length !== 0) {
                                    sort = "&OrderBy=";
                                    o.sort.forEach(val => {
                                        if (val.dir === 'asc') {
                                            sort += val.field;
                                        } else {
                                            sort += '-' + val.field;
                                        }
                                        sort += ",";
                                    });
                                }

                                if (!!o.filter && !!o.filter.filters) {
                                    o.filter.filters.forEach(val => {
                                        
                                        switch (val.operator) {
                                            case 'startswith':
                                                filtes += '&' + val.field + 'StartsWith' + '=' + val.value;
                                                break;
                                            case 'contains':
                                                filtes += '&' + val.field + 'Contains' + '=' + val.value;
                                                break;
                                            case 'doesnotcontain':
                                                filtes += '&' + val.field + 'Contains' + '!=' + val.value;
                                                break;
                                            case 'eq':
                                                filtes += '&' + val.field + '=' + val.value;
                                                break;
                                            case 'neq':
                                                filtes += '&' + val.field + '!=' + val.value;
                                                break;
                                            case 'endwith':
                                                filtes += '&' + val.field + 'EndWith' + '=' + val.value;
                                                break;
                                            case 'gte':
                                                filtes += '&' + val.field + '>=' + val.value;
                                                break;
                                            case 'gt':
                                                filtes += '&' + val.field + '>' + val.value;
                                                break;
                                            case 'lte':
                                                filtes += '&' + val.field + '<=' + val.value;
                                                break;
                                            case 'lt':
                                                filtes += '&' + val.field + '<' + val.value;
                                                break;
                                            case "isnull":
                                                filtes += '&' + val.field + 'IsNull';
                                                break;
                                            case "isnotnull":
                                                filtes += '&' + val.field + 'IsNotNull';
                                                break;

                                        }

                                    });
                                }
                                if(filterParams!=null)
                                    output = pages + sort + filtes +filterParams+ "&apex-company-code=" + Apex.Core.Kendo.GridView.getCompanyCode();
                                else output = pages + sort + filtes + "&apex-company-code=" + Apex.Core.Kendo.GridView.getCompanyCode();
                                
                                break;
                        }
                        return dh!=undefined && dh!=null ? output+dh:output;
                    }
                },
                schema: {
                    data(result) {                   
                        
                        return result.Results || [];
                    },
                    total(result) {
                        return result.Total || 0;
                    },
                    model: schemaModel
                }
            });
            $(document).on("apex-company-change", function (e) {

            });
            
            
            return dataSource;
        }
    }
    enum VirtualScroll {
        Virtual,
        None,
    }
}