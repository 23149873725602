/// <reference path="./../_reference.ts"/>
module Apex.Core {
    export class Module {
        public app: angular.IModule;
        moduleName: string;
        namespace: any;


        public static serviceUrl: string;

        constructor(name: string, modules: Array<string>, namespace: any) {
            var modName = 'apex.webUI.' + name;
            this.moduleName = modName;
            this.app = angular.module(this.moduleName, modules);
            this.regAll(namespace);
            

        }


        private regAll(namespace) {

            if (typeof namespace.Configs !== "undefined")
                this.regConfigs(namespace.Configs);

            if (typeof namespace.Services !== "undefined")
                this.regServices(namespace.Services);

            if (typeof namespace.Directives !== "undefined")
                this.regDirectives(namespace.Directives);

            if (typeof namespace.Controllers !== "undefined")
                this.regControllers(namespace.Controllers);
            this.regqProvide();

        }
        
        private regqProvide(){
            this.app.config(['$qProvider', function ($qProvider) {
                $qProvider.errorOnUnhandledRejections(false);
            }]);
        }


        private regControllers(namespace: any, byPass?: (s) => boolean) {
            this.wire(namespace, this.app.controller);
        }

        public regRoutes(rt: Array<Interfaces.IRoute>, rootState: string = null, otherviceUrl: string = null) {
            this.wireRoutes(rt, rootState, otherviceUrl);
        }

        private regServices(namespace: any) {
            this.wire(namespace, this.app.service);
        }

        private regConfigs(namespace: any) {
            this.wireConfigAndRun(namespace, Core.Config, this.app.config);
        }

        private regDirectives(namespace, byPass?: (s) => boolean) {
            for (var key in namespace) {
                if (namespace.hasOwnProperty(key)) {
                    try {
                        if (byPass != null && byPass(key)) {
                            continue;
                        }
                        var reg: any = (namespace[key]);
                        var dirName = key.charAt(0).toLowerCase() + key.slice(1);

                        this.app.directive(dirName, reg.factory());

                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }

        public regRun(namespace: any) {
            this.wireConfigAndRun(namespace, Core.Run, this.app.run);

        }

        private run(run: Function) {
            this.app.run(run);
        }

        public addFilters(name: string, apexFilter: Core.Interfaces.IFilter) {
            this.app.filter(name, () => {
                return apexFilter.filter;
            });
        }

        public addDirective(name: string, apxDirective: any) {
            this.app.directive(name, apxDirective.factory());
        }

        private wireRoutes(rt: Array<Interfaces.IRoute>, rootState: string = null, otherviceUrl: string = null) {
            this.app.config(($stateProvider, $urlRouterProvider) => {
                return new Core.Utils.StateUtils($stateProvider, $urlRouterProvider, rt, this.moduleName, rootState, otherviceUrl);
            });


            this.app.run(['$rootScope', ($rootScope) => {

                if ($rootScope.appNavigation === null || $rootScope.appNavigation === undefined) {
                    $rootScope.appNavigation = [];
                }
                $rootScope.config = {
                    autoHideScrollbar: false,
                    theme: 'light',
                    advanced:{
                        updateOnContentResize: true
                    },
                        setHeight: 200,
                        scrollInertia: 0
                };

                var fillRoute = function (rt, parent?) {
                    rt.forEach(item => {
                        if (item.navigation != null && item.navigation !== undefined) {
                            var nav;
                            if (item.childRoutes) {
                                nav = {
                                    navigationClass: item.navigation.navigationClass,
                                    caption: item.navigation.caption,
                                    childRoutes: []
                                };
                                item.childRoutes.forEach(route => {
                                    if (route.navigation !== null && route.navigation !== undefined) {
                                        var childRoutes: any = {
                                            caption: route.navigation.caption,
                                            hasStrip: route.navigation.hasStrip,
                                            actionId: route.navigation.actionId,
                                            routeState: route.state
                                        };
                                        nav.childRoutes.push(childRoutes);

                                      
                                        if (route.childRoutes && route.childRoutes.length > 0) {
                                            fillRoute(route.childRoutes, childRoutes);
                                        }
                                    }
                                });
                            } else {
                                nav = {
                                    caption: item.navigation.caption,
                                    actionId: item.navigation.actionId,
                                    hasStrip: item.navigation.hasStrip,
                                    routeState: item.state
                                };
                            }
                            if (parent) {
                                if (!parent.childRoutes) {
                                    parent.childRoutes = [];
                                }
                                parent.childRoutes.push(nav);
                            } else {
                                $rootScope.appNavigation.push(nav);
                            }
                        }
                    });
                };

                fillRoute(rt);
            }]);
        }

        private wire(namespace: any, registrator: (string, Function) => angular.IModule, byPass?: (s) => boolean) {
            for (var key in namespace) {
                if (namespace.hasOwnProperty(key)) {
                    try {
                        if (byPass != null && byPass(key)) {
                            continue;
                        }
                        var reg: any = (namespace[key]);
                        if (typeof reg.getName !== "undefined")
                            registrator(reg.getName(), reg);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }

        private wireConfigAndRun(namespace: any, extendClassName: any, registrator: (Function) => angular.IModule, byPass?: (s) => boolean) {
            for (var key in namespace) {
                if (namespace.hasOwnProperty(key)) {
                    try {
                        if (byPass != null && byPass(key)) {
                            continue;
                        }
                        var reg: any = (namespace[key]);
                        if (reg.prototype instanceof extendClassName) {

                            registrator(reg);
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
    }
}