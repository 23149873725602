/// <reference path="./../_reference.ts"/>
module Apex.Core.Interfaces {
    "use strict";
    export interface IDropdownOptions {
        ngOptions: any;
        dataSource: any;
        value: string;
        valueText: string;
        refresh?: void;
    }
} 