/// <reference path="./../_reference.ts"/>
module Apex.Core.Models {
    "use strict";
    export class Route {
        state: string;
        url: string;
        templateUrl: string;
        controller: string;
        controllerAs: string;
        template: string;
        isAbstract: boolean;

        constructor(state: string, url: string, controller: string = null, template: string = null, templateUrl: string = null, isAbstract: boolean = false) {
            this.state = state;
            this.url = url;
            this.templateUrl = templateUrl;
            this.controller = controller;
            this.template = template;
            this.isAbstract = isAbstract;
        }
    }
}  