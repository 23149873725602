/// <reference path="./../_reference.ts"/>
module Apex.Core {
    export class Injection {

        public static definedArguments(args: IArguments): string[] {
            var functionText = args.callee.toString();
            var foundArgs = /\(([^)]+)/.exec(functionText);
            if (foundArgs[1]) {
                return foundArgs[1].split(/\s*,\s*/);
            }
            return [];
        }

        public static dependence;

        constructor(args: IArguments, injection: string[]) {

            var expectedInjections = _.zip(Injection.definedArguments(args), injection);

            Injection.dependence = expectedInjections;

            _.each(expectedInjections, val => {
                var injectionId = val[0];
                var argument: string = val[1];
                if (argument == null) {
                    throw "missing injection id.  Argument for " + injectionId + " is undefined. Make sure to add the ID as part of the $inject function";
                }
            });
        }


    }
} 