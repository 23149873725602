/// <reference path='./../_reference.ts' />
module Apex.Core {
    "use strict";
    export class Service {
        public static $inject: string[] =
        [
            Globals.http, Globals.filter, Globals.rootScope, Globals.q, Globals.location, Globals.serviceStack, Globals.toaster, '$window', 'ehttp', Globals.state
        ];
        public static getName() {

            var funcNameRegex = /function (.{1,})\(/;
            var results = (funcNameRegex).exec(this.prototype["constructor"].toString());
            var name = (results && results.length > 1) ? results[1] : "";
            return name.charAt(0).toLowerCase() + name.slice(1) + 'Service';
        }

        public static serviceName = '';

        http: angular.IHttpService;
        url: string;
        filter: any;
        rootScope: any;
        q: angular.IQService;
        location: angular.ILocationService;
        serviceStackRestClient: any;

        constructor($http: any, $filter: angular.IFilterService, $rootScope: any,
            private $q: angular.IQService, $location: angular.ILocationService, serviceStackRestClient: any,
            private toaster: any, public $window: any, private ehttp, private $state: any) {
            this.http = $http;
            this.filter = $filter;
            this.rootScope = $rootScope;
            this.q = $q;
            this.location = $location;
            this.serviceStackRestClient = serviceStackRestClient;
            //this.http.defaults.transformRequest.push(data => {
            //    this.rootScope.$broadcast('httpCallStarted');
            //    return data;
            //});

            //this.http.defaults.transformResponse.push(data => {
            //    this.rootScope.$broadcast('httpCallStopped');
            //    return data;
            //});

            this.httpGet('/auth').then(result => { 
                if (result === null) {
                    $location.path('/login');
                }
            });

        }

        filterUrl(mainUrl, params) {
            var gUrl = mainUrl;
            var pr = '';
            if (!!params) {
                pr = this.filter('stringFormat')(params.nm, params.pr);
            }
            if (mainUrl.params) {
                gUrl = this.filter('stringFormat')(mainUrl.url, mainUrl.params);
            }

            var cUrl = this.filter('stringFormat')("{0}", [gUrl]);
            if (pr !== '') {
                cUrl = this.filter('stringFormat')("{0}?{1}", [gUrl, pr]);
            }

            if (typeof Global.Settings.serviceUrl === "undefined")
                throw "Global serviceURl is undefined";
            return Global.Settings.serviceUrl + cUrl;
        }

        httpGet(url, params: any = null, spin: boolean = true) {
            if (params != null && typeof params.pr !== "undefined" && params.pr.length > 0) {
                for (var i = 0; i < params.pr.length; i++) {
                    if (params.pr[i] instanceof Date) {
                        var jDate = Core.Utils.Helper.toLocalTimeISO(params.pr[i]);
                        params.pr[i] = jDate;
                    }
                }
            }
            var cUrl = this.filterUrl(url, params);
            return this.serviceStackGet(cUrl, spin);
        }


        httpGetCached(url, params: any = null) {
            if (params != null && typeof params.pr !== "undefined" && params.pr.length > 0) {
                for (var i = 0; i < params.pr.length; i++) {
                    if (params.pr[i] instanceof Date) {
                        var jDate = Core.Utils.Helper.toLocalTimeISO(params.pr[i]);
                        params.pr[i] = jDate;
                    }
                }
            }
            var cUrl = this.filterUrl(url, params);
            return this.ehttp.etagGet({ url: cUrl }).then(resp => {
                return resp.data;
            });
        }

        private serviceStackGet(url, spin: boolean = true, etag = false) {

            return this.serviceStackRestClient.get(url).then(
                (result) => {
                    return result.data;
                },
                (error) => {
                    console.log(error);
                    if (error.response.status !== 401)
                        this.toaster.pop('error', 'Status code : ' + error.response.status, this.getErrorMessage(error));
                    return this.$q.reject(error);
                });
        }

        private getErrorMessage(error: any) {            
            if (error && error.response) {
                if (error.response.data
                    && error.response.data.ResponseStatus
                    && error.response.data.ResponseStatus.Message)
                    return error.response.data.ResponseStatus.Message;

                if (error.response.status)
                    switch (error.response.status) {
                        case 400: return 'Bad request!';
                        case 407: return 'Proxy Auth!';
                        case 500: return 'Server Error!';
                    }
            }
            return 'Error!';
        }

        httpPost(url, params: any, skipPopUpForStatusCodes: Array<string> = null) {
            return this.serviceStackRestClient.post(url, params).then(
                (result) => {
                    return result.data;
                },
                (error) => {
                    if (skipPopUpForStatusCodes != null) {
                        var er = _.find(skipPopUpForStatusCodes, function (statusCode) { return statusCode == error.response.status; });
                        if (er != undefined && er != null)
                            return error;
                    }
                    this.toaster.pop('error', 'Status code : ' + error.response.status, this.getErrorMessage(error));
                    return error;
                });
        }

        httpPut(url, params: any) {

            return this.serviceStackRestClient.put(url, params).then(
                (result) => {

                    return result.data;
                },
                (error) => {
                    this.toaster.pop('error', 'Status code : ' + error.response.status, this.getErrorMessage(error));

                    return error;
                });
        }

        httpPatch(url, params: any) {

            return this.serviceStackRestClient.patch(url, params).then(
                (result) => {

                    return result.data;
                },
                (error) => {
                    this.toaster.pop('error', 'Status code : ' + error.response.status, this.getErrorMessage(error));

                    return error;
                });
        }

        httpDelete(url: string, params: any = null) {
            return this.serviceStackRestClient.delete(url, params).then(
                (result) => {
                    return result.data;
                },
                (error) => {
                    this.toaster.pop('error', 'Status code : ' + error.response.status, this.getErrorMessage(error));
                    return error;
                });
        }

        recursiveHttp(url, page, resultArray) {
            var skip = page * 100;
            var self = this;
            return self.serviceStackGet(url + "&skip=" + skip).then(reslt => {
                var concatArray = resultArray.concat(reslt.Results);
                if (reslt.Total > skip)
                    return self.recursiveHttp(url, page + 1, concatArray);
                else {
                    return concatArray;
                }
            });
        }

        getDataToExport(url) {
            return this.recursiveHttp(url, 0, []).then(resp => {
                return resp;
            });
        }

        httpExportToCsv(url, params: any) {
            var cUrl = this.filterUrl(url, params);
            return this.http.get(cUrl).then((result: any) => {
                var element = angular.element('<a/>');
                element.attr({
                    href: 'data:attachment/csv;charset=utf-8,' + result.data,
                    target: '_blank',
                    download: url + '.csv'
                })[0].click();
            }).catch(error => {
                if (!!error.data.ResponseStatus) {
                    console.log({
                        title: error.data.ResponseStatus.ErrorCode,
                        message: error.data.ResponseStatus.Message
                    });
                }
                if (error.status === 401) {
                    var deferred = this.q.defer();
                    this.location.path('/access/login');
                    return deferred.promise;
                }
                return error;
            });
        }
    }
}  