/// <reference path="./../_reference.ts"/>
module Apex.Core.Filters {
    export class DateFormat implements Core.Interfaces.IFilter {
        filter(d: any) {
            if (!!d) {
                if (d.getMonth)
                    return d;
                var myDate = new Date(parseInt(d.replace('/Date(', '')));
                return myDate;
            }
            return null;
        }
    }
} 