/// <reference path="./../_reference.ts"/>
module Apex.Core.Kendo {
    export class Grid {
        dataSource: kendo.data.DataSource;
        gridColumns: Array<kendo.ui.GridColumn>;
        gridSchema: kendo.data.DataSourceSchemaModel;


        constructor(private readUrl: string, private columns: Array<Core.Interfaces.IGridColumn> = null, private columnTemplates: Array<Core.Interfaces.IGridColumnTemplate> = null) {
            if (columns != null)
                this.initGridColumns();

            this.initDataSource(readUrl);
        }

        customFilter(filterParams: Array<Interfaces.IGridFilter>): void {
            var filterRow = '';
            var i = 0;
            filterParams.forEach(filter => {
                i++;
                if (filter.filterValue != null && filter.filterValue != undefined && filter.filterValue.length > 0) {
                    filterRow += filter.filterName + filter.filterOperator + '=' + filter.filterValue;
                    if (i < filterParams.length) {
                        filterRow += '&';
                    }
                }
            });
            let read = <kendo.data.DataSourceTransportRead>this.dataSource.options.transport.read;
            let url = read.url.split('?')[0];
            read.url = filterRow !== '' ? url + '?' + filterRow : url;
            this.dataSource.read();
        }


        initGridColumns(): void {

            this.gridSchema = {
                fields: {
                    blocking: { type: "boolean" },
                    recstatus: { type: "boolean" }
                }
            }

            this.gridColumns = new Array();

            this.columns.forEach(item => {
                var gridColumn: kendo.ui.GridColumn = { field: item.field, width: item.width };
                if (item.titleTranslated !== undefined) {
                    gridColumn.headerTemplate = '<span translate="' + item.titleTranslated + '"></span>';
                } else {
                    gridColumn.title = ' ';
                }

                if (item.filterable === undefined) {
                    gridColumn.filterable = {
                        cell: {
                            showOperators: false,
                            operator: item.filterOperator === null || item.filterOperator === undefined ? 'contains' : item.filterOperator,
                            dataSource: new kendo.data.DataSource()
                        }
                    }
                } else {
                    gridColumn.filterable = false;
                }

                if (item.isId) {
                    this.gridSchema.id = item.field;
                }

                if (this.columnTemplates !== null) {
                    var result = $.grep(this.columnTemplates, e => (e.fieldName === item.field));
                    if (result.length !== 0) {
                        gridColumn.template = result[0].template;
                    }
                }
                this.gridColumns.push(gridColumn);
            });

        }

        initDataSource(readUrl: string): void {
            this.dataSource = new kendo.data.DataSource({
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                pageSize: 50,

                transport: {
                    read: {
                        url: Global.Settings.serviceUrl + readUrl,
                        dataType: "jsonp"
                    },
                    parameterMap(o, operation) {
                        var output = null;
                        switch (operation) {
                            case "read":
                                var pages = 'skip=' + o.skip;
                                if (o.take) {
                                    pages += '&take=' + o.take;
                                } else {
                                    return '';
                                }
                                var sort = '';
                                var filtes = '';

                                if (!!o.sort && o.sort.length !== 0) {
                                    if (o.sort[0].dir === 'asc') {
                                        sort = '&OrderBy=' + o.sort[0].field;
                                    } else {
                                        sort = '&OrderByDesc=' + o.sort[0].field;
                                    }
                                }

                                if (!!o.filter && !!o.filter.filters) {
                                    o.filter.filters.forEach(val => {
                                        switch (val.operator) {
                                            case 'startswith':
                                                filtes += '&' + val.field + 'StartsWith' + '=' + val.value;
                                                break;
                                            case 'contains':
                                                filtes += '&' + val.field + 'Contains' + '=' + val.value;
                                                break;
                                            case 'eq':
                                                filtes += '&' + val.field + '=' + val.value;
                                                break;
                                            case 'neq':
                                                filtes += '&' + val.field + '!=' + val.value;
                                                break;
                                            case 'endwith':
                                                filtes += '&' + val.field + 'EndWith' + '=' + val.value;
                                                break;
                                        }

                                    });
                                }
                                output = pages + sort + filtes;
                                break;
                        }
                        return output;
                    }
                },
                schema: {
                    data(result) {
                        return result.Results;
                    },
                    total(result) {
                        return result.Total || 0;
                    },
                    model: this.gridSchema
                }
            });
        }
    }
}