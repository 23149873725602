/// <reference path="./../_reference.ts"/>
module Apex.Core.Configs {
    export class ServiceStack extends Apex.Core.Config {
        public static $injection: string[] = [
            GlobalsProviders.serviceStack
        ];
        constructor(serviceStackRestConfigProvider) {
            super(arguments, ServiceStack.$injection);
            serviceStackRestConfigProvider.setRestConfig({
                urlPrefix: Global.Settings.serviceUrl,
                maxRetries: 0,
                maxDelayBetweenRetries: 4000,
                unauthorizedFn() {
                    var state = Core.Utils.Helper.getServiceFromJs(Globals.state);
                    _.each($(".modal"), item => {
                        var modalScope: any = angular.element(item).scope();
                        modalScope.$close();
                    });
                    if (state.current.name !== "secure.recovery1") {
                        state.go("secure.login");
                    }
                }
            });
        }
    }
} 