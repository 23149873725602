/// <reference path="./../_reference.ts"/>
module Apex.Core.Configs {
    export class Http extends Apex.Core.Config {

        public static $injector: string[] = [
            GlobalsProviders.http, GlobalsProviders.locationProvider
        ];

        constructor($httpProvider, $locationProvider) {
            super(arguments, Http.$injector);
            $httpProvider.defaults.withCredentials = true;
            delete $httpProvider.defaults.headers.common['X-Requested-With'];
            $locationProvider.html5Mode(true);
            $locationProvider.hashPrefix('!');
           // $locationProvider.html5Mode(true).hashPrefix('!');

            //if (window.history && window.history.pushState) {
            //    $locationProvider.html5Mode({
            //        enabled: true
            //    });
            //}
        }
    }
}  