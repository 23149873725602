/// <reference path="./../_reference.ts"/>
module Apex.Core.Interfaces {
    'use strict';
    export interface IRoute {
        state: string;
        url?: string;
        templateUrl?: string;
        controller?: string;
        controllerAs?: string;
        template?: string;
        isAbstract?: boolean;
        navigation?: INavigation;
        childRoutes?: Array<IRoute>;
        isSelected?: boolean;
    }
}