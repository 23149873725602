/// <reference path="./../_reference.ts"/>
module Apex.Core.Interfaces {
    'use strict';
    // @DataContract
    export interface ResponseStatus {
        // @DataMember(Order=1)
        ErrorCode?: string;

        // @DataMember(Order=2)
        Message?: string;

        // @DataMember(Order=3)
        StackTrace?: string;

        // @DataMember(Order=4)
        Errors?: ResponseError[];

        // @DataMember(Order=5)
        Meta?: { [index: string]: string; };
    }
    // @DataContract
    export interface ResponseError {
        // @DataMember(Order=1, EmitDefaultValue=false)
        ErrorCode?: string;

        // @DataMember(Order=2, EmitDefaultValue=false)
        FieldName?: string;

        // @DataMember(Order=3, EmitDefaultValue=false)
        Message?: string;

        // @DataMember(Order=4, EmitDefaultValue=false)
        Meta?: { [index: string]: string; };
    }
}