/// <reference path="./../_reference.ts"/>


module Apex {

    export class Globals {
        public static scope = '$scope';
        public static rootScope = '$rootScope';
        public static location = '$location';
        public static state = '$state';
        public static localStorage = '$localStorage';
        public static translate = '$translate';
        public static http = '$http';
        public static filter = '$filter';
        public static q = '$q';
        public static serviceStack = 'serviceStackRestClient';
        public static toaster = 'toaster';
        public static dialogs = 'dialogs';
        public static modalInstance = '$uibModalInstance';
        public static stateParams = '$stateParams';
        public static ngDialog = "ngDialog";
        public static $timeout = '$timeout';
        public static templateCache = '$templateCache';
    }

    export class GlobalsProviders {
        public static serviceStack = 'serviceStackRestConfigProvider';
        public static provide = '$provide';
        public static http = '$httpProvider';
        public static translate = '$translateProvider';
        public static logProvider = '$logProvider';
        public static locationProvider = '$locationProvider';
    }

}


module Apex.Global {
    export class Settings {
        public static serviceUrl;
    }
}


