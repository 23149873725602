/// <reference path="./../_reference.ts"/>
module Apex.Core.Configs {
    export class GlobalErrors extends Core.Config {

        public static $injector: string[] = [
            GlobalsProviders.provide
        ];
        constructor($provide) {
            super(arguments, GlobalErrors.$injector);

            $provide.decorator("$exceptionHandler", ($delegate, $injector) => (exception, cause) => {
                var toaster = $injector.get("toaster");
                toaster.pop('error', 'Message : Exception', 'Reason : ' + exception);
                $delegate(exception, cause);
            });
        }
    }
} 