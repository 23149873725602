/// <reference path="./../_reference.ts"/>
module Apex.Core.Kendo {

    declare var kendo;

    export class ExportDatasource {


        columnTemplates: any;


        private workBook: any;

        constructor(private gridOptions: any, private translate) {

            this.columnTemplates = gridOptions.grid.columns;
            this.workBook = this.createWorkbook();

        }

        saveExcel(dataSource) {
            if (this.workBook === null)
                return;

            this.fillWorkBook(dataSource);


            kendo.saveAs({
                dataURI: this.workBook.toDataURL(),
                fileName: "erp-cloud-export.xlsx"
            });
        }

        fillWorkBook(dataSource) {
            var sheet = this.workBook.options.sheets[0];
            var columns = this.gridOptions.grid.columns;

            dataSource.forEach(item => {
                var row: any = {};
                row.cells = [];
                row.type = 'data';

                columns.forEach(columnItems => {
                    var val = item[columnItems.field];

                    if (typeof val === "undefined" || val === null)
                        row.cells.push('');
                    else if (val.toString().indexOf('Date(') > 0) {
                        var date = new Date(parseInt(val.replace('/Date(', '')));

                        row.cells.push({ value: date });
                    } else
                        row.cells.push({ value: val });
                });
                sheet.rows.push(row);
            });
        }

        createWorkbook() {
            if (typeof this.columnTemplates === "undefined")
                return null;
            var columns = [];
            var headerRow: any = {
                type: "header",
                cells: []
            }


            var freezPanle = {
                colSplit: 0,
                rowSplit: 1
            };


            this.columnTemplates.forEach(item => {
                var temp: any = _.findWhere(this.gridOptions.apxColumns, (x: any) => { return x.field === item.field });

                var title = '';
                if (typeof temp !== "undefined" && temp !== null && typeof temp.titleTranslated !== "undefined") {
                    title = this.translate.instant(temp.titleTranslated);
                } else {
                    if (item.title && item.title.split("'").length > 0 && item.title.split("'")[1]) {
                        title = this.translate.instant(item.title.split("'")[1]);
                    } else {
                        title = item.field;
                    }
                }
                var firsRow = {
                    background: "#0aa89e",
                    color: "#fff",
                    value: title
                }

                if (!!item.width) {
                    columns.push({ width: item.width });
                } else {
                    columns.push({ autoWidth: true });
                }


                headerRow.cells.push(firsRow);

            });

            return new kendo.ooxml.Workbook({ sheets: [{ title: "Apex ERP Cloud", columns: columns, rows: [headerRow], freezePane: freezPanle }] });
        }

    }
}