/// <reference path="./../_reference.ts"/>
module Apex.Core.Configs {
    export class Translate extends Core.Config {

        public static $injector: string[] =
        [
            GlobalsProviders.translate
        ];

        constructor($translateProvider) {
            super(arguments, Translate.$injector);
            $translateProvider.useUrlLoader(Global.Settings.serviceUrl + '/WebUI/Translates.json');
            $translateProvider.preferredLanguage('ka');
            $translateProvider.useSanitizeValueStrategy('escape');
        }
    }
}