/// <reference path="./../_reference.ts"/>
module Apex.Core.Utils {
    "use strict";
    export class Helper {
        public static removeAttributes(element, ignoreAttributes: string[] = null) {
            var ignore = ignoreAttributes;
            if (ignore === null || typeof ignore === "undefined")
                ignore = [];
            ignore.push('class');
            var attributes = $.map($(element).prop("attributes"), item => item.name);
            attributes.forEach(item => {
                if (typeof _.find(ignore, (x) => { return x === item }) === "undefined")
                    element.removeAttr(item);
            });
        }

        public static changeInput(element) {
            var val = $(element).val();
            $("#apx-col-hidden").val(val);
            $("#apx-col-hidden").trigger("change");
        }

        public static getServiceFromJs(serviceName: string): any {
            return angular.element(document.body).injector().get(serviceName);
        }

        public static safeApply(scope: angular.IScope, fn: any) {
            (scope.$$phase || scope.$root.$$phase) ? fn() : scope.$apply(fn);
        }

        public static getDeepPropertyValue(obj, path) {
            var parts = path.split(".");
            if (parts.length == 1) {
                return obj[parts[0]];
            }
            return this.getDeepPropertyValue(obj[parts[0]], parts.slice(1).join("."));
        }
        public static saveFile = (content, filename, contentType: string = null) => {
            if (!contentType) contentType = "application/octet-stream";
            var a = <any>document.createElement("a");
            var blob = new Blob([content], { 'type': contentType });
            a.href = window.URL.createObjectURL(blob);
            a.target = "_blank";
            a.download = filename;
            a.style = "visibility:hidden";
            document.body.appendChild(a);
            a.click();
            setTimeout(() => { document.body.removeChild(a) }, 500);
        }

        public static directivePreCompile(scope, element, compile, selector: string) {
            var attributes = $(element).prop("attributes");
            var selectInput = $(element).find("input");
            var ignoreAttributes = ['class', 'apx-col-span', 'apx-col-offset', 'apx-dont-mutch', 'options'];
            _.each(attributes, (item: { name; value; }) => {
                if (!_.contains(ignoreAttributes, item.name)) {
                    selectInput.attr(item.name, item.value);
                }
            });
            Helper.removeAttributes(element);
            compile(element)(scope);
        }

        public static toLocalTimeISO(val: Date): string {
            if (val == null)
                return null;
            return new Date(val.getTime() - (val.getTimezoneOffset() * 60000)).toJSON();
        }
    }

    export class Spinner {
        public static animateWithTimer(workFunction: Function, timer?: number) {
            if (timer === null || typeof timer === "undefined")
                timer = 300;
            setTimeout(() => {
                workFunction();
            }, timer);
        }
    }

    export class ModalForm {
        public static openModalForm(htmlTemplate: string, controllerName: string, formData: any, size: ModalSize, closeOnEsc: boolean = true, modalClass = '') {

            var dial = Helper.getServiceFromJs(Globals.dialogs);
            var options = {
                size: '',
                windowClass: 'fade in ' + modalClass,
                animation: true,
                backdrop: 'static',
                keyboard: closeOnEsc
            };
            if (size === ModalSize.Large) {
                options.size = 'lg';
                options.windowClass = 'apx-lg-dialogs fade in';
            } else if (size === ModalSize.Medium) {
                options.size = 'lg';
            } else if (size === ModalSize.Small) {
                options.size = 'sm';
            }
            return dial.create(htmlTemplate, controllerName, formData, options);

        }



        public static openError(errorText: string, isTranslateValue: boolean = false) {
            var dial = Helper.getServiceFromJs(Globals.dialogs);
            var translate = Helper.getServiceFromJs(Globals.translate);

            if (isTranslateValue)
                errorText = translate.instant(errorText);


            var options = {
                size: '',
                windowClass: 'fade in apx-notify-dialog',
                animation: true,
                backdrop: 'static'
            };
            return dial.error(translate.instant('DIALOGS_ERROR'), errorText, options);
        }



        public static openDeleteConfirmations(toDoFunction) {
            var confirmDialog = ModalForm.openConfirmation('deleteConfirmation', true);
            confirmDialog.result.then(() => {
                toDoFunction();
            },()=>{});
        }
        public static openSaveConfimation(toDoFunction, noFunction = null, defaultFunction = null) {
            ModalForm.openConfirmation('saveConfirmation', true).result.then(() => {
                toDoFunction();
                if (defaultFunction != null)
                    defaultFunction();
            }, () => {
                if (noFunction != undefined && noFunction != null)
                    noFunction();
                if (defaultFunction != null)
                    defaultFunction();
            });
        }
        public static openConfirmation(confirmationText: string, isTranslateValue: boolean = false) {
            var dial = Helper.getServiceFromJs(Globals.dialogs);
            var translate = Helper.getServiceFromJs(Globals.translate);
            var options = {
                size: 'md',
                windowClass: 'fade in apx-notify-dialog',
                animation: true,
                backdrop: 'static'
            };


            if (isTranslateValue)
                confirmationText = translate.instant(confirmationText);
                
            return dial.confirm(translate.instant('Attention'), confirmationText, options);
        }
    }

    export enum ModalSize {
        Small, Medium, Large
    }




}  