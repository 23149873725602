/// <reference path="./../_reference.ts"/>
module Apex.Core {

    export class Controller extends Core.Injection {
        public static getName() {
            var funcNameRegex = /function (.{1,})\(/;
            var results = (funcNameRegex).exec(this.prototype["constructor"].toString());
            var name = (results && results.length > 1) ? results[1] : "";
            return name.charAt(0).toLowerCase() + name.slice(1) + 'Controller';
        }

        constructor(args: IArguments, injection: string[]) {
            super(args, injection);
            var http = Apex.Core.Utils.Helper.getServiceFromJs("$http");

            var companyChangeEvent = (e, data) => {
                this.onCompanyChange(data.newCompany, data.oldCompany);
            };
            angular.element(document).on("apex-company-change", companyChangeEvent);
            var scope = injection.indexOf("$scope");
            if (scope !== -1) {
                args[scope].$on('$destroy', function () {
                    angular.element(document).off('apex-company-change', companyChangeEvent);
                });
            }
        }

        onCompanyChange = (newCompany, oldCompany) => { }
    }
} 