/// <reference path="./../_reference.ts"/>
module Apex.Core.Interfaces {
    'use strict';
    export interface IGridColumn {
        field: string;
        isId?: boolean;
        filterable?: any;
        filterTemplate?: Function;
        title?: string;
        titleTranslated?: string;
        width?: number;
        columnTemplate?: string;
        filterOperator?: string;
        objectType?: string;
        hidden?: boolean;
        withTooltip?: boolean;
        editable?: boolean;
        editor?(container: JQuery, options: kendo.ui.GridColumnEditorOptions): void;
        format?: Kendo.Format;
        footerTemplate?: string;
        twoWayBinding?:boolean;
        cssClass?: string;
        template?: any;
        groupHeaderTemplate?: any;
        sortable?:any;
    }
} 