interface Array<T> {
    toDatasource(config?): kendo.data.DataSource;
}
Array.prototype.toDatasource = function (config: any) {
    var that = this;
    if (!config) {
        config = { data: that, pageSize: 50 }
    } else {
        config.data = that;
    }

    return new kendo.data.DataSource(config);
}