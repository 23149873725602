/// <reference path="./../_reference.ts"/>
module Apex.Core.Utils {
    "use strict";
    export class StateUtils {

        public static $inject = ['$stateProvider', '$urlRouterProvider'];

        stateProvider: angular.ui.IStateProvider;
        urlRouterProvider: angular.ui.IUrlRouterProvider;
        routes: Array<Interfaces.IRoute>;
        moduleName: string;
        rootState: string;

        constructor($stateProvider: angular.ui.IStateProvider, $urlRouterProvider: angular.ui.IUrlRouterProvider, routes: Array<Interfaces.IRoute>, moduleName: string, rootState: string
            , private otherviceUrl: string = null) {
            this.stateProvider = $stateProvider;
            this.urlRouterProvider = $urlRouterProvider;
            this.routes = routes;
            this.moduleName = moduleName;
            this.rootState = rootState;

            this.init(routes);
        }
        init(routes: Array<Interfaces.IRoute>): void {

            if (this.otherviceUrl !== null) {
                this.urlRouterProvider.otherwise(this.otherviceUrl);
            }

            routes.forEach(item => {
                item.templateUrl = item.templateUrl;
                var state = this.rootState == null ? item.state : this.rootState + '.' + item.state;
                this.stateProvider.state(state, <angular.ui.IState>
                    {
                        abstract: true,
                        url: item.url,
                        template: item.template,
                        templateUrl: item.templateUrl
                    });
                this.registerChildRoutes(item);
            });

        }
        registerChildRoutes(route: Interfaces.IRoute, ignoreRootState?): void {
            route.childRoutes.forEach(item => {
                item.state = this.rootState !== null && !ignoreRootState ? this.rootState + '.' + route.state + '.' + item.state : route.state + '.' + item.state;
                item.templateUrl = item.templateUrl;
                if (item.childRoutes && item.childRoutes.length > 0) {
                    this.stateProvider.state(item.state, <angular.ui.IState>
                    {
                        abstract: true,
                        url: item.url,
                        templateUrl: item.templateUrl,
                        template: item.template
                    });
                    this.registerChildRoutes(item, true);
                } else {
                    this.stateProvider.state(item.state, <angular.ui.IState>
                    {
                        url: item.url,
                        templateUrl: item.templateUrl,
                        template: item.template,
                        controller: item.controller,
                        controllerAs: item.controllerAs
                    });
                }
            });
        }
    }
}  