///<reference path="./_reference.ts"/>
module Apex.Core {
    'use strict';

    var deps = ['ui.router', 'pascalprecht.translate', 'ngAnimate', 'toaster', 'angular-servicestack', 'dialogs.main', 'AngularEtag','cfp.hotkeys'];

    var coreModule = new Module('core', deps, Core);
    coreModule.addFilters('stringFormat', new Core.Filters.StringFormat);
    coreModule.addFilters('dateFormat', new Core.Filters.DateFormat);

}